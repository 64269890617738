<template>
    <lassoLayout class="page-email-verification">
        <div class="p_email_verification" @paste="onPaste">
            <v-container>
                <div class="verify_your_email">
                    <div class="_heading email_verification">
                        <h1>Verify your email</h1>
                        <h6 v-html="`We've sent a verification code to <span class='email-highlight'>${tempUserEmail}</span> <br>
                        Enter the verification code below`"></h6>
                    </div>
                    <div class="code" ref="codeWrapper">
                        <div class="box_code_inputs">
                            <input type="text" pattern="[0-9]*" inputmode="numeric" maxlength="1" v-model="code.digit_1" ref="digit_1"
                                   @keypress="keypress($event)" @keydown="keydown($event)" @keyup="keyup($event);" @paste="onPaste" @focus="codeFocus(1)" @input="onInput($event, 1)">
                            <input type="text" pattern="[0-9]*" inputmode="numeric" maxlength="1" v-model="code.digit_2" ref="digit_2"
                                   @keypress="keypress($event)" @keydown="keydown($event)" @keyup="keyup($event);" @paste="onPaste" @focus="codeFocus(2)" @input="onInput($event, 2)">
                            <input type="text" pattern="[0-9]*" inputmode="numeric" maxlength="1" v-model="code.digit_3" ref="digit_3"
                                   @keypress="keypress($event)" @keydown="keydown($event)" @keyup="keyup($event);" @paste="onPaste" @focus="codeFocus(3)" @input="onInput($event, 3)">
                        </div>
                        <div class="separation">-</div>
                        <div class="box_code_inputs">
                            <input type="text" pattern="[0-9]*" inputmode="numeric" maxlength="1" v-model="code.digit_4" ref="digit_4"
                                   @keypress="keypress($event)" @keydown="keydown($event)" @keyup="keyup($event);" @paste="onPaste" @focus="codeFocus(4)" @input="onInput($event, 4)">
                            <input type="text" pattern="[0-9]*" inputmode="numeric" maxlength="1" v-model="code.digit_5" ref="digit_5"
                                   @keypress="keypress($event)" @keydown="keydown($event)" @keyup="keyup($event);" @paste="onPaste" @focus="codeFocus(5)" @input="onInput($event, 5)">
                            <input type="text" pattern="[0-9]*" inputmode="numeric" maxlength="1" v-model="code.digit_6" ref="digit_6"
                                   @keypress="keypress($event)" @keydown="keydown($event)" @keyup="keyup($event);" @paste="onPaste" @focus="codeFocus(6)" @input="onInput($event, 6)">
                        </div>
                    </div>

                    <div class="code_submit">
                        <template v-if="code.digit_1 && code.digit_2 && code.digit_3 && code.digit_4 && code.digit_5 && code.digit_6">
                            <button type="button" class="primary_btn small send_code" v-ripple @click="sendCode" :class="{'loading': loader}">
                                Submit
                                <v-progress-circular :width="2" indeterminate></v-progress-circular>
                            </button>
                        </template>
                        <template v-else>
                            <div class="primary_btn small send_code disabled">Submit</div>
                        </template>
                    </div>

                    <div class="message_error">
                        <template v-if="SEND_VERIFY_CODE_STATUS.success === false">
                            {{ SEND_VERIFY_CODE_STATUS.message }}
                        </template>
                    </div>

                    <div class="resend_code">
                        Can't find the code?
                        <template v-if="timer > 1">Send a new code</template>
                        <template v-else>
                            <button type="button" v-ripple @click="resendCode">Send a new code</button>
                        </template>
                        <template v-if="timer > 1">
                            <span class="in_seconds">(in {{ timer }} seconds).</span>
                        </template>
                        <div class="or">
                            Or, <a target="_blank" href="mailto:admin@psychurch.org">contact support.</a>
                        </div>
                    </div>

                </div>
            </v-container>
        </div>
    </lassoLayout>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import lassoLayout from "@/layouts/lassoLayout.vue";
import {required} from "vuelidate/lib/validators";

export default {
    name: 'email-verification',
    components: {
        lassoLayout,
    },
    data() {
        return {
            loader: false,

            code: {
                digit_1: '',
                digit_2: '',
                digit_3: '',
                digit_4: '',
                digit_5: '',
                digit_6: '',
            },

            timer: 60,
            pastedText: '',
            arrayCode: [],
            codeValid: true,
            codeFocusEl: '',

            tempUserEmail: '',
            tempUserPassword: ''
        }
    },
    watch: {},
    // Validations
    validations: {
        code: {
            first: {
                required,
                maxLength: 1
            },
            two: {
                required,
                maxLength: 1
            },
            three: {
                required,
                maxLength: 1
            },
            four: {
                required,
                maxLength: 1
            },
            five: {
                required,
                maxLength: 1
            },
            six: {
                required,
                maxLength: 1
            }
        }
    },
    computed: {
        ...mapGetters(['AUTH_DATA', 'LOGIN_STATUS', 'SEND_VERIFY_CODE_STATUS'])
    },
    mounted() {
        this.tempUserEmail = localStorage.getItem('TEMP_USER_EMAIL');
        // If there is no data in storage, we don't know which user this is, so we redirect them to the login page.
        if (!this.tempUserEmail) {
          this.$router.push({name: 'login'});
        }

        this.$nextTick(() => {
            this.$refs.codeWrapper.focus();
            this.$refs.digit_1.focus();

            // automatically send the code if the user arrived from the login page.
            let isMoveFromLogin = localStorage.getItem('IS_MOVE_FROM_LOGIN');
            if (isMoveFromLogin === 'true') {
              this.resendCode();

              return;
            }

            this.countDownTimer();
        });
    },
    methods: {
        ...mapActions({
            sendVerifyCode: 'SEND_VERIFY_CODE',
            resendVerifyCode: 'RESEND_VERIFY_CODE',
            autoLogin: 'LOGIN'
        }),
        onPaste(event) {
            event.preventDefault();
            const pastedText = event.clipboardData ? event.clipboardData.getData('text') : '';
            this.distributeCode(pastedText, 1);
        },
        onInput(event, digitIndex) {
            const inputValue = event.target.value;
            if (inputValue.length > 1) {
                event.preventDefault();
                this.distributeCode(inputValue, digitIndex);
            }
        },
        distributeCode(inputCode, startIndex) {
            const cleanCode = inputCode.replace(/\D/g, '').slice(0, 6);
            for (let i = 0; i < cleanCode.length; i++) {
                const digitIndex = startIndex + i;
                if (digitIndex <= 6) {
                    this.code[`digit_${digitIndex}`] = cleanCode[i];
                }
            }
            const focusIndex = Math.min(startIndex + cleanCode.length, 6);
            this.$nextTick(() => {
                this.$refs[`digit_${focusIndex}`].focus();
            });
        },
        keyup(event) {
            let activeFocus;
            if (((event.keyCode !== 17 || event.keyCode !== 91) && event.keyCode !== 88) && event.keyCode >= 48 && event.keyCode <= 57 || event.keyCode >= 96 && event.keyCode <= 105) {
                activeFocus = this.$refs[`digit_${this.codeFocusEl + 1}`];
            }
            if (activeFocus) {
                this.$nextTick(() => {
                    activeFocus.focus();
                })
            }
        },
        codeFocus(el) {
            this.codeFocusEl = el;
        },
        keypress(event) {
            if (event.key === 'v') {
                return;
            }

            // only allow numbers
            if ((event.keyCode < 48 || event.keyCode > 57)) {
                event.preventDefault();
            } else {
                this.code[`digit_${this.codeFocusEl}`] = event.key;
            }
        },
        keydown(event) {
            let activeFocus;

            switch (event.key) {
                case 'ArrowLeft':
                    activeFocus = this.$refs[`digit_${this.codeFocusEl - 1}`];
                    break;
                case 'ArrowRight':
                    activeFocus = this.$refs[`digit_${this.codeFocusEl + 1}`];
                    break;
                case 'Backspace':
                    event.preventDefault();
                    this.code[`digit_${+this.codeFocusEl}`] = '';
                    activeFocus = this.$refs[`digit_${this.codeFocusEl - 1}`];
                    break;
                case 'Delete':
                    event.preventDefault();
                    this.code[`digit_${+this.codeFocusEl}`] = '';
                    activeFocus = this.$refs[`digit_${this.codeFocusEl + 1}`];
                    break;
            }
            if (activeFocus) {
                this.$nextTick(() => {
                    activeFocus.focus();
                })
            }
        },
        sendCode() {
            let finalCode = `${this.code.digit_1}${this.code.digit_2}${this.code.digit_3}${this.code.digit_4}${this.code.digit_5}${this.code.digit_6}`;

            if (finalCode.length === 6 && this.tempUserEmail) {
                this.loader = true;
                const params = {
                    email: this.tempUserEmail,
                    code: finalCode
                }
                this.sendVerifyCode(params)
                    .then(() => {
                        if (this.SEND_VERIFY_CODE_STATUS.success === true && this.SEND_VERIFY_CODE_STATUS.tokens) {
                            localStorage.removeItem('TEMP_USER_EMAIL');

                            this.$store.commit('SAVE_TOKENS', this.SEND_VERIFY_CODE_STATUS.tokens);
                            this.$router.push({name: 'profile-basics'});
                        } else {
                            this.$store.commit('SET_SEND_VERIFY_CODE_STATUS', {success: false, message: 'Invalid code'});
                        }
                    })
                    .catch(err => {
                        console.error(`sendCode ${err}`);
                    })
                    .finally(() => {
                        this.loader = false;
                    })
            } else {
                this.$router.push({name: 'login'});
            }
        },
        resendCode() {
            this.timer = 60;
            if (this.tempUserEmail) {
                this.resendVerifyCode({email: this.tempUserEmail}).then(() => {
                    this.$store.commit('SET_SEND_VERIFY_CODE_STATUS', {});
                    this.countDownTimer();
                });
            } else {
                console.log('resendCode redirect to login');
                this.$router.push({name: 'login'});
            }
        },
        countDownTimer() {
            if (this.timer > 0) {
                setTimeout(() => {
                    this.timer -= 1;
                    this.countDownTimer();
                }, 1000);
            }
        },
    }
}
</script>

<style lang="scss">
.page-email-verification {
    padding-top: 40px;
}

.email-highlight {
    font-weight: bold;
    color: white;
    text-align: center;
}

@media (max-width: 600px) {
    .email-highlight {
        display: block;
        margin-top: 5px;
    }
}
</style>
